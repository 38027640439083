import React from 'react';
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import {useIntl} from "gatsby-plugin-react-intl";

const PrivacyPolicy = () => {

    const intl = useIntl().formatMessage;

    return (
        <Layout fullwidth>
            <SEO title={intl({ id: 'privacyPolicy.title' })} />
            <section id="menu" className="our-menu bg-clear-day">
                <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                    <div className="row">
                        <div className="d-flex align-items-center mb-4">
                            <h2 className="fs-3 mb-0">{intl({ id: 'privacyPolicy.title' })}</h2>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: intl({ id: 'privacyPolicy.text' })}} />
                    </div>
                </div>
            </section>
        </Layout>
    )

};

export default PrivacyPolicy;
